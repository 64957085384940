import "./styles/style.css";
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import "./js/menuSlider.js";
import interact from "interactjs";
import Swiper, { Navigation, Pagination, Thumbs } from "swiper";

// BAG
let alsoLike = new Swiper('.also-like-swiper', {
    slidesPerView: "1.7",
    spaceBetween: 15,
    height: 'auto',
    navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
    },
    breakpoints: {
        769: {
            spaceBetween: 50,
            slidesPerView: '4',
            slidesPerGroup: 4,
            speed: 500,
        },
    },
    modules: [Navigation],
});

let savedForLater = new Swiper('.saved-for-later-swiper', {
    slidesPerView: "1",
    spaceBetween: 0,
    height: 'auto',
    navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
    },
    breakpoints: {
        0: {
            spaceBetween: 15,
            noSwiping: true,
            noSwipingClass: 'swiper-slide',
            direction: 'vertical',
        },
        769: {
            spaceBetween: 20,
            slidesPerView: '4.6',
            direction: 'horizontal',
            slidesPerGroup: 4,
            speed: 500,
        },
    },
    modules: [Navigation],
});

// Checkout
let billing = new Swiper('.billing-swiper', {
    slidesPerView: "1.30",
    spaceBetween: 15,
    navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
    },
    pagination: {
        el: ".swiper-pagination",
    },
    breakpoints: {
        769: {
            spaceBetween: 15,
            slidesPerView: "2.13",
        },
    },
    modules: [Navigation, Pagination],
});

let address = new Swiper('.address-swiper', {
    slidesPerView: "1.30",
    spaceBetween: 15,
    navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
    },
    pagination: {
        el: ".swiper-pagination",
    },
    breakpoints: {
        769: {
            spaceBetween: 15,
            slidesPerView: "2.13",
        },
    },
    modules: [Navigation, Pagination],
});

let sample = new Swiper('.sample-swiper', {
    slidesPerView: "3.56",
    spaceBetween: 15,
    breakpoints: {
        769: {
            spaceBetween: 30,
            slidesPerView: "4.13",
        },
    },

});

let swiperThumbs = new Swiper('.thumbs-swiper', {
    slidesPerView: "6.5",
    spaceBetween: 10,
    watchSlidesProgress: true,
    breakpoints: {
        768: {
            slidesPerView: "5.5",
        },
    },
});

let product = new Swiper('.product-swiper', {
    slidesPerView: "1",
    spaceBetween: 15,
    loop: true,
    navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
    },
    thumbs: {
        swiper: swiperThumbs
    },
    modules: [Navigation, Thumbs],
});

let homepagebestseller = new Swiper('.homepage-bestsellers-swiper', {
    slidesPerView: "2",
    spaceBetween: 20,
    breakpoints: {
        768: {
            slidesPerView: "4",
            spaceBetween: 48,
            slidesPerGroup: 4,
            speed: 500,
        },
    },
    navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
    },
    pagination: {
        el: ".swiper-pagination",
    },
    modules: [Navigation, Pagination],
});

let bestseller = new Swiper('.bestsellers-swiper', {
    slidesPerView: "2",
    spaceBetween: 20,
    breakpoints: {
        768: {
            slidesPerView: "4",
            spaceBetween: 48,
            slidesPerGroup: 4,
            speed: 500,
        },
    },
    navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
    },
    modules: [Navigation],
});

let shopCategories = new Swiper('.shopCategories-swiper', {
    slidesPerView: "1.2",
    spaceBetween: 20,
    breakpoints: {
        768: {
            slidesPerView: "4",
            spaceBetween: 20,
            slidesPerGroup: 4,
            speed: 500,
        },
    },
});
let banner = new Swiper('.banner-slider', {
    slidesPerView: "1",
    spaceBetween: 0,
    height: 'auto',
    breakpoints: {
        768: {
            height: 500,
        },
    },
});

let cardSlider = new Swiper('.cms-cards_slider', {
    slidesPerView: "1.9",
    spaceBetween: 10,
    height: 'auto',
    breakpoints: {
        768: {
            slidesPerView: "4",
            spaceBetween: 40,
        },
    },
});
let topBanner = new Swiper('.top-banner', {
    slidesPerView: "1",
    spaceBetween: 10,
    height: 'auto',
    navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
    },
    modules: [Navigation],
});

// target elements with the "draggable" class
interact('.draggable')
    .draggable({
        // enable inertial throwing
        // inertia: true,
        passive: false, // Add this option
        // call this function on every dragmove event
        onmove: dragMoveListener,
        // call this function on every dragend event
        onend: function (event) {
            event.target.classList.remove('dragging')
            event.target.removeAttribute("data-x");
            event.target.removeAttribute("data-y");
            event.target.style.transform = "translate(0px, 0px)";
        },
        modifiers: [
            interact.modifiers.restrictRect({
                restriction: document.querySelector('#container'),
                endOnly: true,
            }),
        ],


    });


function dragMoveListener(event) {
    let target = event.target,
        // keep the dragged position in the data-x/data-y attributes
        x = (parseFloat(target.getAttribute('data-x')) || 0) + event.dx,
        y = (parseFloat(target.getAttribute('data-y')) || 0) + event.dy;
    if (window.innerWidth < 767) {
        // translate the element
        target.classList.add('dragging')
        target.style.webkitTransform =
            target.style.transform =
            'translate(' + x + 'px, ' + y + 'px)';

        // update the posiion attributes
        target.setAttribute('data-x', x);
        target.setAttribute('data-y', y);
    }
}

// enable draggables to be dropped into this
interact('.dropzone').dropzone({
    // only accept elements matching this CSS selector
    // accept: '#drag-1',
    // Require a 75% element overlap for a drop to be possible
    overlap: 0.80,

    // listen for drop related events:

    ondropactivate: function (event) {
        // add active dropzone feedback
        event.target.classList.add('drop-active');
    },
    ondragenter: function (event) {
        let draggableElement = event.relatedTarget,
            dropzoneElement = event.target;

        // feedback the possibility of a drop
        dropzoneElement.classList.add('drop-target');
        draggableElement.classList.add('can-drop');
    },
    ondragleave: function (event) {
        // remove the drop feedback style
        event.target.classList.remove('drop-target');
        event.relatedTarget.classList.remove('can-drop');
        event.relatedTarget.classList.remove('drop-ok');//enlever la class
    },
    ondrop: function (event) {
        event.relatedTarget.classList.add('drop-ok'); //ajouter la class
    },
    ondropdeactivate: function (event) {
        // remove active dropzone feedback
        event.target.classList.remove('drop-active');
        event.target.classList.remove('drop-target');
    }
});
