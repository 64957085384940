import Alpine from 'alpinejs'
import Iodine from '@caneara/iodine';

let iodine = new Iodine();
//
Alpine.data("form", form);
Alpine.start();

function form() {
    return {
        inputElements: [],
        init() {
            // //Store an array of all the input elements with 'data-rules' attributes
            this.inputElements = [...this.$el.querySelectorAll("input[data-rules]")];
            this.initDomData();
            //add translated messages on init on setErrorMessage
            window.Iodine.setErrorMessage("required", 'Please enter a value');
            window.Iodine.setErrorMessage("minLength", '[FIELD] must be minimum [PARAM] characters length');
            window.Iodine.setErrorMessage("numericOrAlphanumeric", 'The value must be either only letters or contain both letters and numbers (excluding only numbers).');
            window.Iodine.setErrorMessage("phoneNumber", 'The phone number format is not valid');
            window.Iodine.setErrorMessage("name", 'Numbers are not allowed');
            window.Iodine.rule('numericOrAlphanumeric', (value) => {
                const regex = /^[a-zA-Z]+$|^(?=.*[a-zA-Z])(?=.*\d).+$/;
                return regex.test(value);
            }, 'The value must be either a numeric string or contain both letters and numbers.');
            window.Iodine.rule('phoneNumber', (value) => {
                const regex = /^(\+\d{1,2}\s?)?1?-?\.?\s?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/;
                return regex.test(value);
            }, 'The phone number format is not valid');
            window.Iodine.rule('name', (value) => {
                const regex = /^[a-zA-Z ]{2,30}$/;
                return regex.test(value);
            }, 'Numbers are not allowed');
            this.updateErrorMessages();
        },
        initDomData: function () {
            //Create an object attached to the component state for each input element to store its state
            this.inputElements.forEach((ele) => {
                this[ele.name] = {
                    serverErrors: JSON.parse(ele.dataset.serverErrors),
                    blurred: false
                };
            });
        },
        updateErrorMessages: function () {
            //map throught the input elements and set the 'errorMessage'
            this.inputElements.forEach((ele) => {
                this[ele.name].errorMessage = this.getErrorMessage(ele);
            });
        },

        getErrorMessage: function (ele) {
            //Return any server errors if they're present
            if (this[ele.name].serverErrors.length > 0) {
                return input.serverErrors[0];
            }
            //Check using iodine and return the error message only if the element has not been blurred
            const error = window.Iodine.assert(ele.value, JSON.parse(ele.dataset.rules));
            if (!error.valid && this[ele.name].blurred) {
                return error.error;
            }
            //return empty string if there are no errors
            return "";
        },
        submit: function (event) {
            const invalidElements = this.inputElements.filter((input) => {
                return (
                    window.Iodine.assert(input.value, JSON.parse(input.dataset.rules)).valid !== true
                );
            });
            if (invalidElements.length > 0) {
                event.preventDefault();
                document.getElementById(invalidElements[0].id).scrollIntoView();
                //We set all the inputs as blurred if the form has been submitted
                this.inputElements.forEach((input) => {
                    this[input.name].blurred = true;
                });
                //And update the error messages.
                this.updateErrorMessages();
            }
            else {
                document.getElementById('spinner')?.classList.add('!flex');
            }

        },
        change: function (event) {
            //Ignore all events that aren't coming from the inputs we're watching
            if (!this[event.target.name]) {
                return false;
            }
            if (event.type === "input") {
                this[event.target.name].serverErrors = [];
                this[event.target.name].value = event.target.value;
            }
            if (event.type === "focusout") {
                this[event.target.name].blurred = true;
            }
            //Whether blurred or on input, we update the error messages
            this.updateErrorMessages();
        }
    };
}
