const navSlide = () => {
    const burgher = document.getElementById('burgher');
    const nav = document.querySelector('.nav-box');
    const blur = document.querySelectorAll('.blur');
    const toggleBox = document.getElementById('toggle-box');
    const body = document.getElementsByTagName("body")
    const bodyBlur = document.getElementById('body-blur')
    toggleBox?.addEventListener('click', () => {
        nav?.classList.toggle('nav-active');
        blur.forEach((el) =>
            el.classList.toggle('display-blur')
        )
        bodyBlur.classList.toggle('blur-show')
        body[0].classList.toggle('!overflow-hidden');
    })
    burgher?.addEventListener('click', () => {
        nav?.classList.toggle('nav-active');
        blur.forEach((el) =>
            el.classList.toggle('display-blur')
        )
        bodyBlur.classList.toggle('blur-show')
        body[0].classList.toggle('!overflow-hidden');
    })
}
const addTransitionsEffects = () => {
    const menuDropdowns = document.querySelectorAll('.expandable');
    const navBox = document.querySelector('.nav-box');
    navBox?.classList.add('transition-all')
    navBox?.classList.add('duration-500')
    menuDropdowns?.forEach((el) => {
        el.classList.add('transition-transform');
        el.classList.add('duration-500');
    })
}

setTimeout(addTransitionsEffects, 500);
navSlide();